import React, { PropsWithChildren, ReactElement } from "react";
import styles from "./BannerCardAndImage.module.scss";

interface BannerCardAndImageProps {
    data: any;
}

const BannerCardAndImage = (
    props: PropsWithChildren<BannerCardAndImageProps>
): ReactElement => {
    const {
        banner_background,
        banner_title,
        banner_subtitle,
        banner_text,
        banner_cta_text,
        banner_cta_url
    } = props.data;
    const withImage = banner_background.kind === "image";

    return (
        <div
            className={`
                ${styles.heroCardAndImage}
                ${
                    withImage
                        ? styles.withImageBackground
                        : styles.withVideoBackground
                }
            `}
            style={{
                backgroundImage: withImage
                    ? `url(${banner_background.url})`
                    : undefined,
            }}
        >
            {!withImage && (
                <video autoPlay muted loop src={banner_background.url} />
            )}

            <div className={styles.textContainer}>
                {banner_title[0] && (
                    <h2 className={styles.title}>{banner_title[0]?.text}</h2>
                )}
                {banner_subtitle[0] && (
                    <p className={styles.subtitle}>
                        {banner_subtitle[0]?.text}
                    </p>
                )}
                <div className={styles.overflow}>
                    {banner_text &&
                        banner_text.map((paragraph: any, i: number) => (
                            <p key={i}>{paragraph.text}</p>
                        ))}
                </div>

            </div>
        </div>
    );
};

export default BannerCardAndImage;
